<template>
  <el-dialog title="添加配置模块"
             v-model="addDispose"
             :before-close="handleClose"
             width="30%">
    <div class="addForm">
      <el-form :model="addDisposeForm"
               :rules="rules"
               ref="addDisposeRef"
               label-width="100px"
               class="demo-ruleForm">
        <el-form-item label="模块名称"
                      prop="name">
          <el-input v-model="addDisposeForm.name"
                    placeholder="请输入模块名称"></el-input>
        </el-form-item>
        <el-form-item label="作品类型"
                      prop="type">
          <el-select v-model="addDisposeForm.type"
                     placeholder="请选择作品类型"
                     :disabled="isPreview">
            <el-option label="视频"
                       value="1"></el-option>
            <el-option label="图片"
                       value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="作品数量"
                      prop="number">
          <el-input v-model="addDisposeForm.number"
                    placeholder="请输入作品数量"
                    :disabled="isPreview"></el-input>
        </el-form-item>
        <div class="url-btn">
          <el-button type="text">自定义链接</el-button>
        </div>
        <el-form-item label="模块跳转链接">
          <el-input v-model="addDisposeForm.url"
                    placeholder="请输链接地址"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="footer-btns">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary"
                 @click="addClick">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { defineComponent, getCurrentInstance, toRefs, reactive, watch, ref } from 'vue'

export default defineComponent({
  emits: ['closeAdd', 'addDisposeReq', 'editDisposeReq'],
  components: {
  },
  props: {
    disposeShow: {
      type: Boolean,
      default: () => false
    },
    detailData: {
      type: Object,
      default: () => { }
    },
    preview: {
      type: Boolean,
      default: () => false
    }
  },
  setup (props, context) {
    const { proxy } = getCurrentInstance()
    const addDisposeRef = ref(null)
    watch(props, (val) => {
      data.addDispose = val.disposeShow
      data.detailDataInfo = val.detailData
      data.isPreview = val.preview
      if (val.preview) {
        data.addDisposeForm = {
          name: val.detailData.title,
          type: val.detailData.media_type + '',
          number: val.detailData.max_num,
          url: val.detailData.link_url
        }
      } else {
        data.addDisposeForm = {
          name: '',
          type: '1',
          number: '',
          url: ''
        }
      }
    })
    const data = reactive({
      addDispose: false,
      detailDataInfo: {}, // 数据
      isPreview: false, // 是否查看详情
      addDisposeForm: {
        name: '',
        type: '1',
        number: '',
        url: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入模块名称', trigger: 'change' }
        ],
        type: [
          { required: true, message: '请输入作品类型', trigger: 'change' }
        ],
        number: [
          { required: true, message: '请输入作品数量', trigger: 'change' }
        ]

      }
    })

    // 关闭
    const handleClose = () => {
      context.emit('closeAdd')
      data.addDispose = false
      data.isPreview = false
      addDisposeRef.value.resetFields()
    }

    const addClick = () => {
      addDisposeRef.value.validate((valid) => {
        if (valid) {
          if (data.isPreview) {
            editBlock()
          } else {
            addDisposeReq()
          }
        } else {
          return proxy.$notify({
            title: '系统通知',
            message: '请正确填写表单',
            type: 'error',
            duration: 2000
          })
        }
      })
    }

    const addDisposeReq = async () => {
      try {
        await proxy.$api.postAddBlockConf({
          title: data.addDisposeForm.name,
          block_type: 1,
          media_type: +data.addDisposeForm.type,
          link_type: 2,
          link_url: data.addDisposeForm.url,
          max_num: +data.addDisposeForm.number
        })
        proxy.$notify({
          title: '系统通知',
          message: '添加成功',
          type: 'success',
          duration: 2000
        })
        data.addDispose = false
        context.emit('addDisposeReq')
        addDisposeRef.value.resetFields()
      } catch (err) {
        return proxy.$notify({
          title: '系统通知',
          message: err,
          type: 'error',
          duration: 2000
        })
      }
    }

    // 编辑模块儿
    const editBlock = async () => {
      try {
        await proxy.$api.postEditBlockConf({
          title: data.addDisposeForm.name,
          block_id: data.detailDataInfo.id,
          link_url: data.addDisposeForm.url
        })
        proxy.$notify({
          title: '系统通知',
          message: '编辑成功',
          type: 'success',
          duration: 2000
        })
        data.addDispose = false
        context.emit('editDisposeReq')
        addDisposeRef.value.resetFields()
      } catch (err) {
        return proxy.$notify({
          title: '系统通知',
          message: err,
          type: 'error',
          duration: 2000
        })
      }
    }

    // const
    return {
      ...toRefs(data),
      handleClose,
      addDisposeReq,
      addDisposeRef,
      editBlock,
      addClick
    }
  }
})
</script>

<style lang="scss" scoped>
.addForm {
  padding: 0px 30px 0px 0px;
  .url-btn {
    padding-left: 100px;
  }
}
.footer-btns {
  text-align: right;
  padding-bottom: 10px;
  margin-top: 25px;
}
.url-btn::v-deep(.el-button) {
  color: #409eff;
}
</style>
